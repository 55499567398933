import { Component, Input, OnInit, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IBranchOffice } from 'src/app/core/models/branch-office.model';
import { BranchOfficeService } from 'src/app/core/services/branch-office.service';

@Component({
  selector: 'app-branch-office-selector',
  templateUrl: './branch-office-selector.component.html',
  styleUrls: ['./branch-office-selector.component.scss']
})
export class BranchOfficeSelectorComponent implements OnDestroy {
  @Input() control: FormControl;
  @Output() branchOfficeSelected: EventEmitter<IBranchOffice> = new EventEmitter<IBranchOffice>();
  branchOffices$: Observable<IBranchOffice[]>;
  private unsubscribe$ = new Subject<void>();

  constructor(private branchOfficeService: BranchOfficeService) {
    this.branchOffices$ = this.branchOfficeService.branchOffices$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onBranchOfficeSelected(branchOffice: IBranchOffice): void {
    this.branchOfficeSelected.emit(branchOffice);
  }

  customSearchFn(term: string, item: IBranchOffice) {
    term = term.toLowerCase();
    return item.nombreFantasia.toLowerCase().indexOf(term) > -1 || item.direccionPostal.toLowerCase() === term;
}

}
