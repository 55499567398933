import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolePipe } from './role.pipe'; // Importa tu pipe personalizado aquí

@NgModule({
    declarations: [
        RolePipe // Agrega todos tus pipes personalizados aquí
    ],
    imports: [
        CommonModule
    ],
    exports: [
        RolePipe // Exporta tus pipes para que puedan ser usados fuera de este módulo
    ]
})
export class PipesModule { }
