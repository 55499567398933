import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalSeleccionBranchOfficeComponent } from './modal-seleccion-branch-office/modal-seleccion-branch-office.component';
import { BranchOfficeSelectorComponent } from './branch-office-selector/branch-office-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [ModalSeleccionBranchOfficeComponent, BranchOfficeSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    ModalSeleccionBranchOfficeComponent,
    BranchOfficeSelectorComponent
  ]
})
export class ComponentsModule { }
