// Definición de la interfaz IUser
export interface IUser {
  uid?: string;
  name?: string;
  surname?: string;
  token?: string;
  email?: string;
  role?: string;
  memberBranchOfficeId?: string;
  photoURL?: string;

  isAdmin(): boolean;
  isSystemAdmin(): boolean;
  isCoworkAdmin(): boolean;
  isMember(): boolean;
}

export class User implements IUser {
  constructor(
    public uid?: string,
    public surname?: string,
    public email?: string,
    public role?: string,
    public memberBranchOfficeId?: string,
    public name?: string,
    public token?: string,
    public photoURL?: string
  ) {}

  isAdmin(): boolean {
    return this.role.includes('admin');
  }

  isSystemAdmin(): boolean {
    return this.role === "system-admin";
  }

  isCoworkAdmin(): boolean {
    return this.role === "cowork-admin";
  }

  isMember(): boolean {
    return !this.isAdmin();
  }
}
