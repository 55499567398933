const ENV={
  production: true
}

const prod = {
  
  production: ENV.production,
  url: 'https://us-central1-onework-digit.cloudfunctions.net/',
  api: 'http://api.onework.com.ar/api/',
  ip: '127.0.0.1'
  
};

const dev = {
  
  production: ENV.production,
  url: ENV.production ? 
  'http://localhost:5001/onework-digit/us-central1/' : 
  'https://us-central1-onework-test.cloudfunctions.net/',
  // 'http://localhost:5001/onework-test/us-central1/',
  api: 'http://api.onework.com.ar/api/',
  ip: '127.0.0.1'
  
};

export const GLOBAL = ENV.production ? prod : dev;