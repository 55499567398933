import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { NgApexchartsModule } from 'ng-apexcharts';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const users: User[] = [
      Object.assign(new User, {
        uid: '1',
        name: 'test',
        email: 'ubold@coderthemes.com',
        surname: 'User',
        role: 'member',
        memberBranchOfficeId: '32rf1f'
      })
    ];

    const authHeader = request.headers.get('Authorization');
    const isLoggedIn =
      authHeader && authHeader.startsWith('Bearer fake-jwt-token');

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(
        mergeMap(() => {
          // authenticate - public
          if (request.url.endsWith('/api/login') && request.method === 'POST') {
            const user = users.find(
              x =>
                x.email === request.body.email
            );
            if (!user) {
              return error('Email or password is incorrect');
            }
            return ok({
              id: user.uid,
              username: user.name,
              firstName: user.name,
              lastName: user.surname,
              email: user.email,
              token: `fake-jwt-token`
            });
          }

          // get all users
          if (request.url.endsWith('/api/users') && request.method === 'GET') {
            if (!isLoggedIn) {
              return unauthorised();
            }
            return ok(users);
          }

          // pass through any requests not handled above
          return next.handle(request);
        })
      )
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());

    // private helper functions

    function ok(body) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function unauthorised() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function error(message) {
      return throwError({ status: 400, error: { message } });
    }
  }
}

export let FakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
