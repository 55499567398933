import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBranchOffice } from '../models/branch-office.model';

@Injectable({
  providedIn: 'root'
})
export class BranchOfficeService {

  private collectionName = "branchOffices"
  private branchOfficesCollection: AngularFirestoreCollection<IBranchOffice>;
  private branchOfficesSubject: BehaviorSubject<IBranchOffice[]> = new BehaviorSubject<IBranchOffice[]>(null);
  branchOffices$: Observable<IBranchOffice[]> = this.branchOfficesSubject.asObservable();

  /**
   * Branch office seleccionado. Este es el que el usuario selecciona para guardar, por ejemplo,
   * en un miembro. Al crear un miembro, se lo registra en un branch office en particular
  */
  // branch office seleccionado
  private branchOfficeSubject: BehaviorSubject<IBranchOffice> = new BehaviorSubject<IBranchOffice>(null);
  branchOffice$: Observable<IBranchOffice | null> = this.branchOfficeSubject.asObservable();

  
  /**
   * Este es el branch office que maneja el user logeado.
   */
  public userBranchOffice$: BehaviorSubject<IBranchOffice> = new BehaviorSubject<IBranchOffice>(null);

  constructor(
    private firestore: AngularFirestore) {
    this.branchOfficesCollection = this.firestore.collection<IBranchOffice>(this.collectionName);
    this.fetchBranchOffices();
  }

  private fetchBranchOffices(): void {
    this.branchOfficesCollection.valueChanges({ idField: 'id' }).subscribe(branchOffices => {
      this.branchOfficesSubject.next(branchOffices);
    });
  }
  
  getUserBranchOffice(id: string | null): void {
    if (!id) {
      this.userBranchOffice$.next(null);
      return;
    }
    this.branchOfficesCollection.doc(id).valueChanges().subscribe((branchOffice: IBranchOffice) => {
      this.userBranchOffice$.next(branchOffice);
    });
  }

  getBranchOfficeById(id: string | null): void {
    if (!id) {
      this.branchOfficeSubject.next(null);
      return;
    }
    this.branchOfficesCollection.doc(id).valueChanges().subscribe((branchOffice: IBranchOffice) => {
      this.branchOfficeSubject.next(branchOffice);
    });
  }

  agregarBranchOffice(branchOffice: IBranchOffice): Promise<void> {
    branchOffice.id = this.firestore.createId();
    return this.branchOfficesCollection.doc(branchOffice.id).set(branchOffice);
  }

  actualizarBranchOffice(branchOffice: IBranchOffice): Promise<void> {
    return this.branchOfficesCollection.doc(branchOffice.id).update(branchOffice);
  }

  eliminarBranchOffice(id: string): Promise<void> {
    return this.branchOfficesCollection.doc(id).delete();
  }
}
