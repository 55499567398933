import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IBranchOffice } from 'src/app/core/models/branch-office.model';
import { BranchOfficeService } from 'src/app/core/services/branch-office.service';


@Component({
  selector: 'app-modal-seleccion-branch-office',
  templateUrl: './modal-seleccion-branch-office.component.html',
  // styleUrls: ['./modal-seleccion-branch-office.component.css']
})
export class ModalSeleccionBranchOfficeComponent implements OnInit {
  closeResult = '';
  filteredBranchOffices: IBranchOffice[] = [];
  searchTerm: string = '';
  selectedBranchOffice: IBranchOffice | null = null;

  @Output() branchOfficeSelected = new EventEmitter<IBranchOffice>();

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal, 
    private branchOfficeService: BranchOfficeService
  ) { }

  ngOnInit(): void {
    this.branchOfficeService.branchOffices$.subscribe(branchOffices => {
      this.filteredBranchOffices = branchOffices;
    });
  }

  closeModal(): void {
    this.modalService.dismissAll();
    this.selectedBranchOffice = null;
    this.searchTerm = '';
  }

  selectBranchOffice(branchOffice: IBranchOffice): void {
    this.selectedBranchOffice = branchOffice;
  }

  accept(): void {
    if (this.selectedBranchOffice) {
      this.branchOfficeSelected.emit(this.selectedBranchOffice); // Emitir el branch office seleccionado al componente padre
      this.activeModal.close();
    }
  }

  filterBranchOffices(): void {
    const term = this.searchTerm.trim().toLowerCase();
    this.filteredBranchOffices = this.filteredBranchOffices.filter(branchOffice =>
      branchOffice.nombreFantasia.toLowerCase().includes(term) ||
      branchOffice.direccionPostal.toLowerCase().includes(term)
    );
  }

}
