import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'role'
})
export class RolePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'member':
                return 'Miembro';
            case 'system-admin':
                return 'Adminstrador de sistema';
            case 'cowork-admin':
                return 'Adminstrador de coworking';
            default:
                return value;
        }
    }
}
