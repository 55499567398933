import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieService } from '../services/cookie.service';
import { IUser, User } from '../models/auth.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBranchOffice } from '../models/branch-office.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  user: IUser;

    // branch office seleccionado
    private userBranchOffice: BehaviorSubject<IBranchOffice> = new BehaviorSubject<IBranchOffice>(null);
    userBranchOffice$: Observable<IBranchOffice | null> = this.userBranchOffice.asObservable();
    
  constructor(
    private cookieService: CookieService,
    public router: Router,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {}

  /**
   * Returns the current user
   */
  public currentUser(): User {
    if (!this.user) {
      this.user = Object.assign(new User(), JSON.parse(this.cookieService.getCookie('currentUser')));
      if(!this.user.uid) return null;
    }
    return this.user;
  }

  getAuth() {
    return this.afAuth;
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    // console.log("login => ", email, password);    
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Logout the user
   */
  logout() {
    // remove user from local storage to log user out
    this.cookieService.deleteCookie('currentUser');
    this.user = null;
  }

  resetPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }
}
