import { GLOBAL } from './../app/core/services/global';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const firebaseTest = {
  apiKey: "AIzaSyDdXlaBg1K5ZUoDve--ClvfGwJy2rpOXDY",
  authDomain: "onework-test.firebaseapp.com",
  projectId: "onework-test",
  storageBucket: "onework-test.appspot.com",
  messagingSenderId: "322067302110",
  appId: "1:322067302110:web:1bcda08e04ff2b107de44f"
};

export const firebaseProd = {
  apiKey: 'AIzaSyC1fcD-_U2qkjuXqPagdsk4eAmMAuAggDA',
  authDomain: 'onework-digit.firebaseapp.com',
  databaseURL: 'https://onework-digit.firebaseio.com',
  projectId: 'onework-digit',
  storageBucket: 'onework-digit.appspot.com',
  messagingSenderId: '20942595284',
  appId: '1:20942595284:web:e45bd413f6067601'
};

export const environment = {
  production: GLOBAL.production,
  firebase: GLOBAL.production ? firebaseProd : firebaseTest
  
};
// console.log("environment => ", environment)
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
